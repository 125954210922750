import React from 'react'
import { Layout } from '../../containers/Layout'
import { Text } from '../../components/Text'
import { MobileContent } from '../../components/grid/MobileContent'
import styled from 'styled-components/macro'
import rem from 'polished/lib/helpers/rem'

const Page = () => (
  <Layout>
    <Text size="h2" weight="light" uppercase center marginBottom="size8">
      El pago no se ha realizado
    </Text>
    <MobileContent>
      <Content>
        <p>El pago ha sido cancelado por lo que no se te ha cobrado nada</p>
        <p>
          Si tienes problemas para realizar la compra online, ponte en contacto
          conmigo para solucionarlo escribíendome un whatsapp o llamándome al:{' '}
          <a href="tel:+699762837">699 762 837</a>
        </p>
      </Content>
    </MobileContent>
  </Layout>
)

const Content = styled.div`
  max-width: ${rem(600)};
  margin-left: auto;
  margin-right: auto;

  text-align: center;
`

export default Page
